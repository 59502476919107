import { Flex, IconButton, Text } from "@chakra-ui/react";
import * as React from "react";
import { ReactNode } from "react";
import { FaArrowLeft } from "react-icons/fa";

interface BackNavigationProps {
  title: string;
  rightContent?: ReactNode;
}
export const BackNavigation = (props: BackNavigationProps) => {
  const { title, rightContent } = props;
  const handleBack = (e) => {
    e.preventDefault();
    history.back();
  };
  return (
    <Flex alignItems="center" justifyContent="space-between" width="100%">
      <IconButton
        backgroundColor="white"
        aria-label="Back"
        size="lg"
        mr="2"
        icon={<FaArrowLeft />}
        onClick={handleBack}
      />
      <Text
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        color="delta"
        fontWeight="bold"
        fontSize="xl"
      >
        {title}
      </Text>
      <Flex minWidth="12" ml="2" minHeight="12">
        {rightContent}
      </Flex>
    </Flex>
  );
};
