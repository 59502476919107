import { Flex, Heading } from "@chakra-ui/react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as React from "react";
import { BackNavigation } from "../components/BackNavigation";
import { Header } from "../components/Header";
import { Layout } from "../components/Layout";
import { Main } from "../components/Main";
import { MarkdownContainer } from "../components/MarkdownContainer";

const StoryTemplate = ({ data }) => {
  return (
    <Layout justifyContent="center" alignItems="center">
      <Header>
        <BackNavigation title={data.mdx.frontmatter.title} />
      </Header>
      <Main pb="16" justifyContent="flex-start" alignItems="center">
        <Flex flexDirection="column" maxWidth={["100%", null, null, "60ch"]}>
          <Heading
            mt="8"
            mx="6"
            as="h2"
            fontSize="medium"
            textShadow="md"
            color="blackAlpha.700"
            textAlign="left"
            fontStyle="italic"
          >
            {data.mdx.frontmatter.description}
          </Heading>
          <MarkdownContainer>
            <MDXRenderer margin="4">{data.mdx.body}</MDXRenderer>
          </MarkdownContainer>
        </Flex>
      </Main>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }, fileAbsolutePath: { regex: "/content/stories/" }) {
      frontmatter {
        title
        description
      }
      body
    }
  }
`;

export default StoryTemplate;
